
















import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/commons/Logo.vue";

@Component({
  components: {
    Logo,
  },
})
export default class LoginLayout extends Vue {}
