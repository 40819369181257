











import { Component, Vue } from "vue-property-decorator";
import Button from "../../components/commons/inputsAndControls/Button.vue";
import LoginLayout from "../../components/commons/layouts/LoginLayout.vue";

@Component({
  components: {
    Button,
    LoginLayout,
  },
})
export default class ChangedPasswordPage extends Vue {}
