




import { Component, Vue } from "vue-property-decorator";
import logo from "@/assets/img/login_logo.png";

@Component
export default class Logo extends Vue {
  logo = logo;
}
